$blue: #036fb6;
$purple: #6f42c1;
$green: #50b968;

$primary: #a3428a;
$secondary: #f49720;
$success: $green;

$theme-colors: (
	"purple": $purple
);

$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px,
	xxl: 1500px,
	xxxl: 1700px,
);

$spacer: 1rem;
$spacers: (
	-1: ($spacer * -.25),
	-2: ($spacer * -.5),
	-3: ($spacer * -1),
	-4: ($spacer * -1.5),
	-5: ($spacer * -3)
);

@import "~bootstrap";
@import "theme/style.statcast";

@import "utils";

@import "components/Checkbox";
@import "components/Uploader";
@import "components/Box";
@import "components/Table";
@import "components/Button";
@import "components/Card";
@import "components/List";
@import "components/Map";
@import "components/Input";
@import "components/Scrollbar";
@import "components/Menu";
@import "components/Navbar";
@import "components/Badge";
@import "components/Player";
@import "components/Modal";
@import "components/Code";
@import "components/Breadcrumb";
@import "components/Tabs";
@import "components/Tooltip";
@import "components/DatePicker";
@import "components/Chart";

@import "animations";

@import "custom";

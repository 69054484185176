.uploader {
	position: relative;
	padding: 1rem;
	display: flex;
	flex-direction: column;

	.uploader-static {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;

		.uploader-button {
			text-align: center;
		}
	}
	.uploader-files {
		margin-bottom: 1rem;
		text-align: center;
		overflow: hidden;
		background-color: #e0e0e0;

		img {
			max-width: 100%;
			width: auto;
		}
	}
}

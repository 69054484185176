.tooltip {
	.tooltip-inner {
		padding: 0.25rem 0.5rem;
	}

	&.tooltip-default {
		.tooltip-inner {
			background-color: #333333;
			color: #FFFFFF;
		}

		&[x-placement="top"] {
			.arrow {
				&:after {
					border-top-color: #333333;
				}
			}
		}
	}

	@each $name, $color in $theme-colors {
		&.tooltip-#{$name} {
			.tooltip-inner {
				background-color: $color;
				border-color: darken($color, 5%);
				color: color-yiq($color);
			}

			@each $name in ("top", "left", "right", "bottom") {
				&.bs-tooltip-#{$name},
				&.bs-tooltip-auto[x-placement^="#{$name}"],
				&.bs-tooltip-auto[x-placement^="#{$name}"] {
					.arrow {
						&:before {
							border-#{$name}-color: darken($color, 5%);
						}
						&:after {
							border-#{$name}-color: $color;
						}
					}
				}
			}
		}
	}
}
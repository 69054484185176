.checkbox {
	position: relative;
	padding-left: 1.5rem;
	line-height: 0;

	&.checkbox-no-label {
		padding-left: 0;

		label {
			width: 1rem;
			height: 1rem;
		}
	}

	&.checkbox-vertical {
		text-align: center;
		padding-left: 0;
		padding-bottom: 1.5rem;

		input {
			left: 50%;
			top: auto;
			bottom: 0;
			transform: translateX(-50%);
		}

		label {
			&:before, &:after {
				left: 50%;
				top: auto;
				bottom: 0;
				transform: translateX(-50%);
			}
		}
	}

	input {
		position: absolute;
		left: 0;
		top: 0;
		width: 1rem;
		height: 1rem;
		opacity: 0;
		z-index: 1;
		box-sizing: border-box;
		padding: 0;

		&:checked ~ label  {
			&:before {
				background-color: $primary;
				border: 1px solid $primary;
			}
			&:after {
				background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
			}
		}
	}

	label {
		line-height: 24px !important;
		margin-bottom: 0;

		&:before {
			border-radius: .25rem;
			top: 0;
			left: 0;
			box-shadow: none !important;
			border: 1px solid #909090;
			background: transparent none repeat 0 0 / auto auto padding-box border-box scroll;
			background: initial;
			position: absolute;
			display: block;
			pointer-events: none;
			content: "";
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
		}

		&:after {
			top: 0;
			left: 0;
			position: absolute;
			display: block;
			content: "";
			background-repeat: no-repeat;
			background-position: center center;
			background-size: 50% 50%;
		}
	}

	&.checkbox-inversed {
		input {
			&:checked ~ label  {
				&:before {
					background-color: $white;
					border: 1px solid $white;
				}
				&:after {
					background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23007bff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
				}
			}
		}

		label {
			&:before {
				border-color: $white;
			}
		}
	}

	$sizes: (small: 0.5rem, normal: 1rem, large: 1.3rem, x-large: 1.7rem);

	@each $name, $size in $sizes {
		&.checkbox-#{$name} {
			input {
				width: $size;
				height: $size;
			}
			label {

				&:before, &:after {
					width: $size;
					height: $size;
				}
			}

			&.checkbox-no-label {
				label {
					width: $size;
					height: $size;
				}
			}
		}
	}
}

.btn {
	$socials: (
		facebook: #3b5999,
		google: #dd4b39,
	);

	@each $social, $color in $socials {
		&.btn-#{$social} {
			background-color: $color;
			border-color: darken($color, 5%);
			color: #FFFFFF;

			&:hover {
				background-color: darken($color, 10%) !important;
				color: #FFFFFF !important;
				border-color: darken($color, 5%) !important;
			}
		}
	}

	&.btn-secondary {
		background-color: $theme-color-2;
		border-color: $theme-color-2;
		color: $button-text-color;

		&:active, &:focus {
			color: $button-text-color;
			background-color: darken($theme-color-2, 5%);
			border-color: darken($theme-color-2, 5%);
		}
		&:hover {
			color: $button-text-color;
			background-color: darken($theme-color-2, 8%);
			border-color: darken($theme-color-2, 8%);
		}
	}

	&.btn-outline-transparent {
		&.btn-outline-secondary {
			&:not(:disabled):not(.disabled):not([disabled]):hover {
				background-color: rgba($primary, .1);

				color: darken($primary, 5%);
			}
		}
		&.btn-outline-danger {
			&:not(:disabled):not(.disabled):not([disabled]):hover {
				background-color: rgba($danger, .1);

				color: darken($danger, 5%);
			}
		}
	}

	@each $name, $color in $theme-colors {
		&.btn-outline-#{$name} {
			&.disabled, &[disabled] {
				&:hover {
					color: $color;
				}
			}
		}
	}
}

.btn-icon {
	background-color: transparent;
	border: none;
	text-align: center;

	display: flex;
	justify-content: center;
	align-items: center;

	font-size: 14px;
	line-height: 34px;
	padding: 0;

	i {
		margin-right: 6px;
		display: block;
	}
	svg {
		pointer-events: none;
	}

	@each $color, $value in $theme-colors {
		&.btn-#{$color} {
			@include button-variant($value, $value);
		}
	}

	@each $color, $value in $theme-colors {
		&.btn-outline-#{$color} {
			@include button-outline-variant($value);
		}
	}

	&.btn-bordered {
		border-width: 1px;
		border-style: solid;
	}
	&.btn-rounded {
		//padding: 10px;

		i {
			margin-right: 0;
		}
	}

	&.small {
		width: 28px;
		height: 28px;
		font-size: 12px;
	}
	&.large {
		width: 44px;
		height: 44px;
		font-size: 18px;
	}
	&.xxx-large {
		width: 80px;
		height: 80px;
		font-size: 18px;
	}
}

.btn-purple {
	background-color: $purple;
	color: #FFFFFF;

	&:hover, &:active, &:focus {
		color: #FFFFFF;
	}

	&:hover {
		background-color: darken($purple, 10%);
	}
}
.btn-outline-purple {
	background-color: #FFFFFF;
	border-color: $purple;
	color: $purple;

	&:hover {
		border-color: darken($purple, 10%);
		background-color: $purple;
		color: #FFFFFF;
	}
}

.text-bold {
	font-weight: bold;
}
.text-nowrap {
	white-space: nowrap;
}

.bg-light-grey {
	background-color: #f0f0f0;
}

.bg-transparent {
	background-color: transparent;
}
.color-inherit {
	color: inherit !important;
}
@for $i from 1 through 9 {
	.bg-transparent-#{$i} {
		background-color: rgba(#ffffff, $i / 10);
	}
}

.aspect-ratio-16by9 {
	position: relative;
	padding-bottom: 56.25%;

	> * {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		width: 100%;
		//height: 100%;
	}
}

.v-align-middle {
	vertical-align: middle;
}

.border-1 {
	border-width: 1px;
	border-style: solid;
}

.color-inherit {
	color: inherit;
}

.circle {
	-webkit-border-radius: 999px;
	-moz-border-radius: 999px;
	border-radius: 999px;
}
.square {
	position: relative;
	&:after {
		content: "";
		display: block;
		padding-bottom: 100%;
	}

	.square-content {
		position: absolute;
		left: 0;
		top: 0;
	}
}

.user-select-none {
	user-select: none;
}

$fontSizes: (1: 1rem, 2: 1.5rem, 3: 2rem);
@each $name, $size in $fontSizes {
	.font-size-#{$name} {
		font-size: $size;
	}
}

.text-medium {
	font-size: 1.5rem;
}

@for $i from 0 through 100 {
	.w-#{$i} {
		width: #{$i * 1%};
	}
	.w-sm-#{$i} {
		@media (min-width: 576px) {
			width: #{$i * 1%};
		}
	}
	.w-md-#{$i} {
		@media (min-width: 768px) {
			width: #{$i * 1%};
		}
	}
	.w-lg-#{$i} {
		@media (min-width: 992px) {
			width: #{$i * 1%};
			max-width: #{$i * 1%};
		}
	}
	.w-xl-#{$i} {
		@media (min-width: 1200px) {
			width: #{$i * 1%};
		}
	}
}

@each $breakpoint in map-keys($grid-breakpoints) {
	@include media-breakpoint-up($breakpoint) {
		$infix: breakpoint-infix($breakpoint, $grid-breakpoints);

		.w#{$infix}-auto {
			width: auto;
		}
	}
}

@each $breakpoint in map-keys($grid-breakpoints) {
	@include media-breakpoint-up($breakpoint) {
		@for $i from 0 through 100 {
			$infix: breakpoint-infix($breakpoint, $grid-breakpoints);

			.flex-basis#{$infix}-#{$i} {
				flex-basis: #{$i * 1%};
			}
		}
	}
}
@each $breakpoint in map-keys($grid-breakpoints) {
	@include media-breakpoint-up($breakpoint) {
		$infix: breakpoint-infix($breakpoint, $grid-breakpoints);

		.flex-basis#{$infix}-auto {
			flex-basis: auto;
		}
	}
}

@each $breakpoint in map-keys($grid-breakpoints) {
	@include media-breakpoint-up($breakpoint) {
		$infix: breakpoint-infix($breakpoint, $grid-breakpoints);

		.border#{$infix}-left-0 {
			border-left-width: 0 !important;
		}
		.border#{$infix}-right-0 {
			border-right-width: 0 !important;
		}
		.border#{$infix}-top-0 {
			border-top-width: 0 !important;
		}
		.border#{$infix}-bottom-0 {
			border-bottom-width: 0 !important;
		}
		.border#{$infix}-left {
			border-bottom-width: $border-width !important;
		}
		.border#{$infix}-right {
			border-bottom-width: $border-width !important;
		}
		.border#{$infix}-top {
			border-bottom-width: $border-width !important;
		}
		.border#{$infix}-bottom {
			border-bottom-width: $border-width !important;
		}
	}
}

.w-max-100 {
	max-width: 100%;
}
.w-max-auto {
	max-width: none;
}

.px--1 {
	padding-left: ($spacer * .25) !important;
}

.text-disabled {
	color: #b4b4b4 !important;
}

@function str-replace($string, $search, $replace: '') {
	$index: str-index($string, $search);

	@if $index {
		@return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
	}

	@return $string;
}
.breadcrumb-container {
	.breadcrumb {
		padding-left: 0;
		padding-top: 0;

		.breadcrumb-item {
			color: $text-muted;

			a {
				color: inherit;
			}

			&.active {
				color: #000000;

				text-decoration: underline;
				&:before {
					text-decoration: none;
				}
			}
		}
	}
}
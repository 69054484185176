.code {
	position: relative;
	padding: 1rem;
	background-color: #f8f9fa;
	-ms-overflow-style: -ms-autohiding-scrollbar;
	border: 1px solid $border-color;
	border-radius: $card-border-radius;

	pre {
		white-space: pre-wrap;
		margin-bottom: 0;
		color: $text-muted;
	}
}
.navbar {
	&.navbar-managed-session {
		background: linear-gradient(90deg, rgba(244,154,32,1) 0%, rgba(232,77,38,1) 100%);
		color: #FFFFFF;

	}
	.navbar-user {
		&:focus, &:hover, &:active {
			color: inherit;
		}
	}
}

.animate-hover-scale {
	transition: transform 125ms linear;

	&:hover {
		transform: scale(1.05);
	}
}


.fade {
	transition: opacity 0.05s linear;
}
::-webkit-scrollbar {
	background-color: #fff;
	width: 16px;
}

::-webkit-scrollbar-track {
	background-color: #fff;
}

::-webkit-scrollbar-thumb {
	background-color: #babac0;
	background: linear-gradient(180deg, rgba(244,154,32,1) 0%, rgba(232,77,38,1) 100%);
	border-radius: 16px;
	border: 4px solid #fff;
}

::-webkit-scrollbar-button {
	display:none;
}

.card-list-header {
	> div {
		border-style: solid;
		border-width: 0;
		border-top-width: 2px;
		border-bottom-width: 2px;
		border-color: transparent;
		padding-top: 2px;
		padding-bottom: 2px;

		&.order-asc {
			border-bottom-color: $primary;
		}
		&.order-desc {
			border-top-color: $primary;
		}
	}
}


//input[type="color"],
//input[type="date"],
//input[type="datetime"],
//input[type="datetime-local"],
//input[type="email"],
//input[type="month"],
//input[type="number"],
//input[type="password"],
//input[type="search"],
//input[type="tel"],
//input[type="text"],
//input[type="time"],
//input[type="url"],
//input[type="week"],
//select:focus,
//textarea {
//	font-size: 16px;
//
//	&.form-control {
//		padding: 8px;
//	}
//}

html {
	height: 100%;
}

body {
	//min-height: 100%;
}

@include media-breakpoint-down(xl) {
	body {
		font-size: 12px;

		&.text-one {
			font-size: 22px;
		}

		&.text-small {
			font-size: 8px;
		}
	}
}

i[class^="statcast-"], i[class*=" statcast-"] {
	&:before {
		margin: 0;
		width: auto;
	}
}

.no-pointer-events {
	pointer-events: none;

	user-select: none;
}

.bg-white {
	background-color: #FFFFFF;
	color: #000000;
}
.bg-light-primary {
	background-color: lighten($primary, 50%);

	.text-muted, .text-disabled {
		color: #c3c3c3 !important;
	}
}

.bg-primary {
	.checkbox {
		label {
			&:before {
				background-color: #FFFFFF;
				border-color: #FFFFFF;
			}
		}

		input:checked ~ label:before {
			background-color: darken($primary, 15%);
			border-color: darken($primary, 15%);
		}
	}
}

.bg-primary, .bg-light-primary {
	.btn {
		background-color: #FFFFFF;

		&.btn-primary, &.btn-outline-primary {
			&:not(:disabled):not(.disabled):not([disabled]):hover {
				background-color: $primary;
				border-color: #FFFFFF;
				color: #FFFFFF;
			}
		}
		&.btn-secondary, &.btn-outline-secondary {
			&:not(:disabled):not(.disabled):not([disabled]):hover {
				background-color: $secondary;
				border-color: #FFFFFF;
				color: #FFFFFF;
			}
		}
		&.btn-danger, &.btn-outline-danger {
			&:not(:disabled):not(.disabled):not([disabled]):hover {
				background-color: $danger;
				border-color: #FFFFFF;
				color: #FFFFFF;
			}
		}
		&.btn-purple, &.btn-outline-purple {
			&:not(:disabled):not(.disabled):not([disabled]):hover {
				background-color: $purple;
				border-color: #FFFFFF;
				color: #FFFFFF;
			}
		}
	}
}

.bg-purple {
	background-color: $purple;
}

.form-control {
	height: inherit !important;

	&.input-sm {
		padding: .35em;
	}
}

.card {
	.card-title {
		margin-bottom: 0;
	}
	.card-header {
		padding: 0;
		margin-bottom: 1rem;
	}
}

.react-datepicker__time-container {
	width: 85px !important;

	.react-datepicker__time .react-datepicker__time-box {
		width: 85px !important;
	}
}

.tooltip {
	.tooltip-inner {
		border-radius: .3rem;
	}

	&.bs-tooltip-right {
		.arrow {
			&.color-primary {
				&:before, &:after {
					border-right-color: $theme-color-2;
				}
			}
		}
	}

	//.tooltip-inner {
	//	&[color="primary"], &.color-primary {
	//		background-color: $theme-color-2;
	//		color: $foreground-color;
	//	}
	//}

	&.bs-tooltip-bottom {
		.arrow {
			&[color="primary"], &.color-primary {
				&:before, &:after {
					border-bottom-color: $theme-color-2;
				}
			}
		}
	}
	&.bs-tooltip-top {
		.arrow {
			&[color="primary"], &.color-primary {
				&:before, &:after {
					border-top-color: $theme-color-2;
				}
			}
		}
	}
}

.form-control {
	&.is-invalid, &:invalid {
		border-color: $error-color;

		&:focus {
			border-color: darken($error-color, 10%);
		}
	}
}


.card {
	&.square {
		position: relative;
		padding: 0;

		&:after {
			content: '';
			display: block;
			padding-bottom: 100%;
		}

		.card-body {
			position: absolute;
			width: 100%;
			height: 100%;
		}
	}
}

.modal {
	.modal-footer {
		padding: 1rem;
	}
}

.dropdown {
	.dropdown-menu {
		box-shadow: 0 0 20px -10px rgba(0,0,0,0.4);
		.dropdown-item {
			outline: none;
			cursor: pointer;

			&.disabled, &:disabled {
				cursor: default;
				background-color: inherit;

				&:hover, &:active, &:focus {
					color: #6c757d;
				}
			}
		}
	}
}

.form-control {
	&::placeholder {
		opacity: .4;
	}
}

.custom-control {
	.custom-control-input {
		&.is-invalid {
			& ~ .custom-control-label {
				.invalid-feedback {
					display: block;
				}
			}
		}
	}
}

.invalid-feedback {
	&.visible {
		display: block !important;
	}
}

.input-group {
	& > .form-control, &.custom-select, & > .custom-file {
		&:focus {
			z-index: 0;
		}
	}
}

.badge {
	padding: 0.6em 0.75em 0.6em 0.75em;

	&.badge-disabled {
		background-color: #d6d6d6;
		color: #575757;
	}
}

.overlay-container {
	position: relative;

	.overlay {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 99;
	}
}

[class^="iconsminds-"], [class*=" iconsminds-"] {
	line-height: 1;

	&:before {
		width: auto;
		margin-left: 0;
		margin-right: 0;
	}
}

.main-menu {
	.nav {
		.nav-item {
			a {
				padding: 6px;
			}
		}
	}
}

.object-fit-contain {
	object-fit: contain;
}

.absolute-fill {
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}
.absolute-center {
	top: 50%;
	left: 50%;

	transform: translate(-50%, -50%);
}
.absolute-center-top {
	top: 0;
	left: 50%;

	transform: translateX(-50%);
}
.absolute-center-bottom {
	bottom: 0;
	left: 50%;

	transform: translateX(-50%);
}
.absolute-center-left {
	top: 50%;
	left: 0;

	transform: translateY(-50%);
}
.absolute-center-right {
	top: 50%;
	right: 0;

	transform: translateY(-50%);
}
.absolute-top-left {
	top: 0;
	left: 0;
}
.absolute-top-right {
	top: 0;
	right: 0;
}
.absolute-bottom-left {
	bottom: 0;
	left: 0;
}
.absolute-bottom-right {
	bottom: 0;
	right: 0;
}

.btn-icon {
	&:hover, &:focus, &:active {
		text-decoration: none;
	}
}

.text-semi-small {
	font-size: 0.9rem;
	line-height: 1rem;
}
.text-color-primary {
	color: $primary;
}

.overflow-hidden {
	overflow: hidden;
}
.overflow-auto {
	overflow: auto;
}
.overflow-scroll {
	overflow: scroll;
}

.dropdown {
	.dropdown-menu {
		.dropdown-divider {
			margin: 0;
		}
	}
}

.border, .border-top, .border-bottom, .border-left, .border-right {
	&.border-darker {
		border-color: #d7d7d7 !important;
	}
	&.border-primary {
		border-color: rgba(3, 111, 182, 0.6) !important;
	}

	@for $i from 0 through 10 {
		&.border-width-#{$i} {
			border-width: $i * 1px !important;
		}
	}
}

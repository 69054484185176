.player-footer {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	height: $player-footer-height;
	z-index: 1;

	border-top: 1px solid #e0e0e0;

	background-color: #ffffff;
	@include depth(2);

	margin-left: 0;
	margin-right: $app-menu-width;

	transform: translateY($player-footer-height);
	transition: transform .4s;

	@include respond-below(lg) {
		margin-right: 0;
	}

	@at-root .menu-visible .player-footer {
		margin-left: $main-menu-width;

		@include respond-below(xl) {
			margin-left: $main-menu-width-lg;
		}

		@include respond-below(lg) {
			margin-left: $main-menu-width-md;
		}

		@include respond-below(sm) {
			margin-left: $main-menu-width-xs;
		}
	}

	@at-root body.full .player-footer {
		margin-left: 0;
		margin-right: 0;
	}

	.player-footer-logo {
		img {
			max-height: 100%;
			max-width: 50px;
		}
	}
	.player-footer-toggle {
		position: absolute;
		left: 50%;
		border-radius: 9999px;
		border: 1px solid #e0e0e0;
		background-color: #ffffff;
		width: 34px;
		height: 34px;
		top: 0;

		transform: translateX(-50%) translateY(-110%);
		transition: transform .4s;
	}
	.player-footer-metadata {
		flex-basis: 100%;
		min-width: 0;
		overflow: hidden;

		.player-footer-metadata-title {
			white-space: nowrap;
			text-overflow: ellipsis;
		}
	}

	&.visible {
		transform: translateY(0);

		.player-footer-toggle {
			transform: translateX(-50%) translateY(-50%);
		}
	}

	&:not(.visible) {
		.player-footer-toggle {
			&:hover {
				background-color: #ffffff;

				@include depth(2);
			}
		}
	}
}

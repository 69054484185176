.sidebar {
	.main-menu {
		width: $main-menu-width;

		@include media-breakpoint-down(sm) {
			width: $main-menu-width-xs;
		}

		ul {
			li {
				a {
					height: 90px;
				}
			}
		}
	}
}

.modal {
	.modal-dialog {
		&.modal-transparent {
			box-shadow: none;

			.modal-content {
				background-color: transparent;
			}
		}

		&.modal-mobile-fullscreen {
			@include media-breakpoint-down(xs) {
				width: 100%;
				height: 100%;
				margin: 0;
				padding: 0;
				//padding: 1rem;

				.modal-content {
					//height: auto;
					//min-height: 100%;
					border-radius: 0;
				}
			}
		}
	}
}

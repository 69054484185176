.map-controls {
	.btn {
		font-size: 20px;
		line-height: 20px;

		padding: .2rem .75rem;

		background-color: rgba($primary, .7);
		border: none;

		margin-right: 2px;
		&:last-of-type {
			margin-right: 0;
		}
	}
}

.card {
	border-radius: $card-border-radius;

	&.card-count {
		.card-count-value {
			margin-right: 8px;

			&:last-of-type {
				margin-right: 0;
			}

			.card-count-value-icon {
				font-size: 30px;
				@media (min-width: 576px) {
					font-size: 40px;
				}
			}
		}
	}
}

.react-datepicker__input-container input {
	border-radius: $input-border-radius !important;
}
.form-control {
	border-radius: $input-border-radius;
	box-shadow: initial !important;
}

.input-group {
	.form-control {
		border-radius: $input-border-radius;

		&:not(:last-child) {
			border-radius: $input-border-radius;
		}
	}

	.input-group-prepend {
		.form-control {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}

		.btn {
			border-top-left-radius: $input-border-radius;
			border-bottom-left-radius: $input-border-radius;
		}
	}
	.input-group-append, .input-group-append:not(:last-child) {
		.form-control {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}

		.btn {
			border-top-right-radius: $input-border-radius;
			border-bottom-right-radius: $input-border-radius;
		}
	}

	&:not(.input-group-inside) {
		.form-control:not(:last-child) {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}
	}

	&.input-group-inside {
		position: relative;

		.form-control {
			padding-right: 36px;

			& + .input-group-append {
				position: absolute;
				right: 0;
				top: 0; bottom: 0;
				transition: right 100ms;

				.btn {
					font-size: 0.8rem;
					line-height: 1.5;
					padding: 0.7rem 0.75rem 0.65rem 0.75rem;
					display: block;
					color: $input-border-color;
				}
			}

			&.is-invalid {
				&:not(:focus) {
					& + .input-group-append {
						right: 1.5em;
					}
				}
			}

			&:focus {
				+ .input-group-append {
					.btn {
						&:not(.disabled), &:not(:disabled) {
							color: $primary;
						}
					}
				}
			}
		}
	}
}

.react-select {
	min-width: 150px;

	.react-select__control {
		border-radius: $input-border-radius;
		outline: none;
		box-shadow: none;
	}


	.react-select__menu {
		border-radius: 4px !important;
		overflow: hidden;
		margin-top: -1px;
	}

	.react-select__option {
		cursor: pointer;

		&.react-select__option--is-focused {
			background-color: #FFFFFF;

			&:hover {
				background-color: darken($primary, 8%);
				color: color-yiq($primary);
			}
		}
		&.react-select__option--is-selected {
			background-color: $primary;
			color: color-yiq($primary);
		}
	}

	.react-select__clear-indicator {
		padding-left: 0;
		padding-right: 0;
	}
}

.form-group {
	> label {
		color: $text-muted;
	}
}

textarea {
	&.form-control {
		height: auto !important;
		resize: vertical;
		min-height: 150px;
	}
}

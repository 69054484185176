.table {
	background-color: #FFFFFF;

	thead, tfoot {
		tr {
			background-color: $primary;
			color: $white;
			user-select: none;
		}
	}
	tbody {

		tr {
			td {
				vertical-align: middle;
			}
		}
	}

	&.table-bordered {
		tbody {
			tr {
				td {
					//border-color: #e0e0e0 !important;
				}
			}
		}
	}
	&.table-striped {
		thead {
			background-color: #e0e0e0;
			tr {
				th {
					border: none;
				}
			}
		}

		//tbody tr:nth-of-type(odd)
	}

	&.table-green {
		border-color: $green;

		thead, tfoot {
			tr {
				background-color: $green;
				color: $primary-color;
			}
		}
	}

	&.table-purple {
		border-color: $purple;

		thead, tfoot {
			tr {
				background-color: $purple;
				color: $primary-color;
			}
		}
	}
}

.table {
	.search-container {
		.btn {
			border-radius: 0;
			padding: .25rem 1rem;
		}
	}
}

.ReactTable {
	.rt-table {
		border-color: $primary;
		.rt-thead, .rt-tfoot {
			background-color: $primary;
			color: $primary-color;
			font-size: 1.1em;

			.rt-th {
				&.-sort-asc {
					box-shadow: inset 0 3px 0 0 $secondary;
				}
				&.-sort-desc {
					box-shadow: inset 0 -3px 0 0 $secondary;
				}
			}
		}
		.rt-tbody, .rt-thead, .rt-tfoot {
			.rt-tr {
				align-items: center;

				.rt-td {
					padding-top: 10px;
				}
			}
		}
	}
}

.table-dense {
	td {
		padding: 1rem 0.5rem;
	}
}
